// -----------------------------------------------------------------------------
// Search Module
// -----------------------------------------------------------------------------

.module-search-results {
  position: sticky;
  // z-index: 9999;
  width: 100%;
  max-height: 70vh;
  background: var(--cb-background-default, #fff);
  overflow-y: auto;
  overflow-x: hidden;
  left:0;

  @media only screen and (max-width: 959px) {
    max-height: 100vh;
    top: 20px;
  }

  // Suggestion section
  &__suggestion {
    .terms {
      display: flex;
      gap: 2em;
      padding: 1em;
      overflow-y: auto;

      .item .link {
        display: flex;
        align-items: center;

        svg {
          width: 20px;
          height: 20px;
          padding: 2px;
          fill: #232323;
          transform: rotate(-90deg);
        }
      }
    }
  }

  // Products section
  &__products {
    .item {
      padding: 2% 1% 0;
      background-color: #fff;
      border: {
        left: none;
        right: none;
      }
      transition: box-shadow ease-in;
    }
  }

  // Common elements
  &__heading {
    font-size: calc(var(--font-body-size, 16px) - 2px);
    letter-spacing: .05em;
    margin-left: 0;
    font-weight: 500;
  }

  &__price,
  &__viewAll,
  &__productDesc {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__viewAll {
    padding: 1em;
    box-sizing: border-box;
    border-top: 1px solid var(--cb-primary-light, #ccc);
  }

  &__viewAll-link{
    flex-grow: 1;
    text-align: center;
  } 

  &__viewAll-close{
    margin-left: auto;
  } 

  &__productDesc {
    .product-description-wrapper {
      text-align: center;
    }
    .product-name {
      margin: 10px 0 0;
    }
  }

  &__productImage {
    overflow: hidden;
  }
}
