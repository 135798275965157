@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/**************************[ BASE ]**************************/
body {
	font-family: 'nimbus-reg', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #333;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 14px;
	line-height: 24px;
}
h1 {
	font-size:30px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'molde-expanded-semibold', sans-serif;
	font-weight:bold;
	color:#000;
}
h2 {
	font-size:22px;
	font-weight:bold;
	letter-spacing:0;
	text-transform: none !important;
	line-height:30px;
	font-family: 'nimbus-med', sans-serif;
}
h3 {
	font-size:16px;
	font-weight:bold;
	line-height:inherit;
	font-family: 'nimbus-med', sans-serif;
}
h4 {
	font-weight:bold;
	font-size:16px;
	font-family: 'nimbus-med', sans-serif;
}
h6 {
	font-size:24px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'nimbus-med', sans-serif;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items  .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title
{
	font-family: 'ZawyaPro-WideLight', sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#000;
}

/**************************[ HEADER ]**************************/
.header a, .header p, .header span {
	font-size: 14px;
	color: #fff;
	font-family: 'nimbus-reg', sans-serif;
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}
.header-top-container {
	background-color:#222;
	box-shadow:none;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg *, .userCode, .userName {
	color: #fff !important;
}

/* Logo */
.logo img {
	max-width: 250px;
}

/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #777;
	font-family: 'nimbus-reg', sans-serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #777;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #000;
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value {
	color: #000;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #fff !important;
	border-width: 6px 5px 0;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 0;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	top: 8px;
	left: -15px;
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}

/* Menu */
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #fff;
	font-size: 14px;
	font-family: 'nimbus-med', sans-serif;
    font-weight: 600;
}
.links > li > a:hover {
	background-color: #222;;
	color: #ffffff !important;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	background-color: #222;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #000;
}
.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 3px solid #222;
}
#nav .nav-panel--dropdown a {
	color: #000;
	font-size: 14px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 14px;
}
#nav .nav-panel--dropdown a:hover {
	color: #000;
}
.mobnav-trigger.active {
	background-color: #000;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #000;
}
#nav .nav-item--home .fa-home {
	color: #000 !important;
	padding: 0.3rem;
}
#root-wrapper a:hover span.fa:before {
	color: #fff;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #fff;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #000;
	border-right-color: #000;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: rgba(200, 29, 36, 1) !important;
	padding: 0;
	border-radius: 0;
	max-height: 45px;
	font-size: 16px;
	margin-top: 10px;
}
#mini-cart .actions button.button span span:hover {
	background-color: rgba(143, 0, 0, 1) !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}

/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 12px;
	letter-spacing: 1px;
	text-transform: uppercase;
    color:#000;
    font-weight: 700;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 10px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 24px;
	color: #000;
	letter-spacing: 0;
	text-transform: uppercase;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #fff;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: rgba(200, 29, 36, 1);
	border-radius: 0;
	padding: 0;
}
button, .button, .button a, button a {
	font-family: 'nimbus-med', sans-serif;
	font-size: 16px;
}
button.button span {
	font-size: 16px;
}
button.button span span {
	padding: 0 24px;
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: rgba(143, 0, 0, 1) !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 14px;
	color: #000;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #000;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #f5f5f5;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

/**************************[ Footer ]**************************/
.footer-container {
	background-color: #ddd;
}
.footer-container, .footer-top-container, .footer-container2, .footer-primary.footer.container, .footer-top
{
	background-color: #222;
}
.footer-top-container {
	padding-top: 10px;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer-bottom-container.section-container {
	background-color: #333;
	color: #999;
    display: none;
}


/************************** MICS **************************/


/*Header*/
.std i {
     font-style:normal;
}
@media only screen and (max-width:1399px) {
     .userSwitch a {
         text-indent:-9999px;
         position:absolute;
    }
     .userSwitch a::after {
         content:"\f2c3";
    }
     .userSwitch a::after {
         font-family:fontAwesome;
         font-size:20px;
         text-indent:0;
         float:left;
         color:#fff;
    }
}
@media only screen and (max-width:959px) {
     .hide-below-960 {
         display:none !important;
    }
     #mini-cart .hide-below-960 {
         display:inline-block !important;
    }
}
@media only screen and (min-width:960px) {
     .hide-above-960 {
         display:none !important;
    }
}
@media only screen and (min-width:768px) {
     .hide-above-768 {
         display:none !important;
    }
}
@media only screen and (max-width:767px) {
     .hide-below-768 {
         display:none !important;
    }
}
/*Top bar on the header*/
.header-top-container .module-header-multi{
     margin-left:0;
     margin-right:0;
     width:100%;
     padding-top: 5px;
    padding-bottom: 5px;
}
.top-header-container .module-header-multi span, .top-header-container .module-header-multi a{
     line-height:2em;
}
.top-header-container .module-header-multi p{
     margin-bottom:0 !important;
     line-height:2em;
}
.top-header-container .item.item-welcome-msg {
     margin-left:0;
}
.top-header-container .item.item-user-links {
     margin-right:0; margin-left:0
}
.top-header-container .item.item-user-links ul.links > li:last-child a{
     padding-right:0;
}
.userSwitch a, .welcome-msg, .userSwitch a:hover, .links>li>a, .item-map a:hover{
     color:#fff !important;
}
.desktop-top-header {
     padding:10px 0;
}
/*Cart*/
.fa.fa-shopping-cart {
     float:right;
     left:inherit !important;
     margin-left:10px;
     margin-right: 5px;
     top:2px !important;
     font-size:20px !important;
     color:#fff;
}
/*Links*/

.module-user-links .links>li>a>.fa{
     font-family:fontAwesome;
     margin-right:5px;
     font-size:20px;
}
/*Logo*/
.desktop-top-header .grid-container {
     display:flex;
     justify-content:center;
}
.desktop-top-header .grid-container .module-logo {
     text-align:center;
}
@media only screen and (min-width:960px) {
    .desktop-top-header .grid-column-wrapper.first{
        position: absolute;
        top: 20px;
    }
    .desktop-top-header .grid-container {
            min-height: 20px;
    }
}
/*Nav*/
.desktop-category-column .module-category-menu .nav-item.nav-item--home {
     display:none;
}
.desktop-category-column .nav-regular .nav-item.level0:hover > a, .desktop-category-column .nav-regular .nav-item.level0:hover > a > span {
     color:#fff;
}
.desktop-category-column .nav-regular .nav-panel li.classic {
     margin-bottom:5px;
}
#nav .nav-item.level0.parent>a .caret {
     border-top-color:#fff;
     opacity:1;
}
.desktop-category-column .grid-container {
     display:flex;
     justify-content:end;
}
.desktop-category-column .grid-container > div {
     margin:auto 0;
}
.desktop-category-column #nav {
     display:flex;
     justify-content:end;
}
@media only screen and (max-width:1199px) {
     .desktop-category-column .nav-regular .nav-item.level0 > a > span, .desktop-category-column #nav .nav-panel--dropdown a>span {
         font-size:12px;
    }
}
@media only screen and (min-width:960px) and (max-width:1365px) {
    .desktop-category-column .category-column {
        width:81.33%;
    }
    .desktop-category-column .search-column {
        width:16.66%;
    }
    .desktop-category-column .search-column .module-search {
    width: 90%;
}
}
@media only screen and (min-width:960px) and (max-width:1199px) {
    .desktop-top-header .logo img {
        max-width:180px;
    }
    .desktop-top-header .grid-column-wrapper.first{
        position: absolute;
        top: 30px;
    }
    .desktop-top-header .grid-container {
            min-height: 10px;
    }
    
}
/*Search*/
.search-column .module-search {
     width:70%;
     float:right;
}
.search-column .module-search .form-search .button-search .fa {
     color:#fff !important;
     padding-right: 0 !important;
}
.search-column .form-search .input-text::placeholder {
     color:#fff;
     font-size:13px;
}
.search-column .form-search .input-text {
     color:#fff;
     font-size:13px;
     background-color:#222;
     border:none;
}
/*Inline search results*/
 #inline-search-results {
     right:0;
     left:initial;
}
@media only screen and (min-width:960px) {
     #inline-search-results {
         width:160%;
         right:0;
         left:initial;
    }
     #inline-search-results .grid12-8.results-right {
         width:68.5%;
    }
     #inline-search-results li.grow {
         width:45%;
    }
}
@media only screen and (min-width:768px) and (max-width:959px){
     #inline-search-results {
         width:570px;
    }
}
@media only screen and (max-width:767px) {
     #inline-search-results {
         width:96%;
         left:0 !important;
         margin:auto;
         max-height:700px;
         overflow:auto;
    }
     #inline-search-results li.grow {
         width:45% !important;
    }
}

/*Tablet*/
@media only screen and (min-width:960px) and (max-width:1259px) {
     .desktop-category-column .nav-regular li.level0>a {
         padding:0 6px;
    }
}
@media only screen and (max-width:959px) {
     .fa-vcard:before{
         content:"" !important;
    }
     .fa-vcard:after{
         content:"\f007";
         color:#fff;
         font-size:20px;
    }
     .top-header-container, .top-header-container .grid-column-wrapper, .top-header-container .module-header-multi, .desktop-top-header{
         overflow:unset;
    }
     .fa.fa-bars, .fa.fa-vcard {
         padding-top:0.1em !important;
    }
     .desktop-top-header .grid-container {
         justify-content:unset;
    }
     .desktop-top-header .grid-container > div {
         margin:auto;
    }
     .desktop-top-header .grid-container > div:last-child {
         margin:auto 0;
    }
     .desktop-top-header .search-column .module-search {
         width:80%;
         float:right;
    }
}
/*Mobile*/
@media only screen and (max-width:767px) {
     .mobile-header {
         margin-bottom:5px !important;
    }
     .mobile-header .header .logo {
         width:unset;
    }
     .mobile-header .grid-full.module-header-multi {
         display:flex;
         overflow:hidden !important;
         padding-top:0;
    }
     .mobile-header .grid-full.module-header-multi > div{
         margin:auto;
    }
     .mobile-header .logo img {
         max-width:120px;
    }
     .mobile-header .cart-total {
         display:none;
    }
     .mobile-header .item-search {
         margin-left:0 !important;
    }
     .mobile-header .item-search .fa.fa-search{
         padding-top:0.1em !important;
         color:#fff !important;
    }
     .fa.fa-map-marker {
         padding:0.1em 0.5em 0.5em;
    }
     .fa.fa-shopping-cart {
         top:0 !important;
    }
     /*Search on mobile*/
     .mobile-header div.form-search {
         width:94%;
         margin:0 auto;
    }
     .mobile-header .module-search .form-search .input-text {
         font-size:16px !important;
    }
     /*Search*/
     .mobile-header .module-search .form-search .input-text {
         display:none;
    }
     .mobile-header .module-search .button.button-search {
         position:unset!important;
    }
     .mobile-header .item-search.fly-out {
         position:fixed;
         display:block!important;
         top:0;
         right:0;
         left:0;
         bottom:0;
         z-index:9999;
         width:100%!important;
         background-color:#fff;
         margin-left:0;
    }
     .mobile-header .item-search.fly-out .form-search .button {
         right:10px;
         position:absolute!important;
         margin:0;
         padding:0;
         font-size:inherit;
    }
     .mobile-header .item-search.fly-out .form-search .button-search {
         right:45px;
    }
     .mobile-header .form-search .button-close .fa {
         font-size:16px;
         color:#000;
         padding:10px;
         font-style:normal;
    }
}

/*Category pages*/
.product-search-container, .breadcrumbs_col .module-category-misc > div, #breadcrumbs li.home span, .breadcrumbs_col .nested-container {
     /*margin-left:0 !important;*/
}
.product-listing .add-cart-button span span{
     padding:0px !important;
     width:120px;
}
.product-listing .page-title h1 {
     padding-bottom:2%;
     color:rgba(200, 29, 36, 1);
}
#breadcrumbs li.last-child > span {
     vertical-align:super;
}
/*sidebar*/
.sidebar-wrapper.module-category-filters .block-title {
     color:rgba(200, 29, 36, 1);
     padding:5px 10px;
}
.sidebar-wrapper.module-category-filters .block-title .fa:before {
     font-size:24px;
}
.sidebar-wrapper.module-category-filters .accordion-style1 li a {
     padding:10px;
     border-bottom:1px solid #ddd;
}
.sidebar-wrapper.module-category-filters .block-content {
     padding:0;
}
/*Pagination*/
.pager {
     border:none;
     margin:0;
     padding:0;
}
.pager li a, .pager strong{
     font-size:14px;
}
.pager .pages li a{
     background-color:#000;
     color:#fff;
}
.pager .pages li.current {
     background-color:rgba(200, 29, 36, 1);
     color:#fff;
}
.pager .pages li a:hover {
     background-color:rgba(200, 29, 36, 1);
}
/*Category Misc*/
.toolbar-section .module-category-misc, .pagination-bottom .module-category-misc {
     display:flex;
     justify-content:space-between;
     padding:1% 0;
     border-top:1px solid #ddd;
}
.pagination-bottom .module-category-misc {
     justify-content:center;
}
.toolbar-section .module-category-misc > div {
     margin:auto 0;
}
.sorter .view-mode span.grid, .sorter .view-mode span.list {
     background-color:rgba(200, 29, 36, 1);
}
.sorter .view-mode .list.active {
     background-position:-30px -237px;
}
.sorter .view-mode .grid.active {
     background-position:0px -237px;
}
.sorter .view-mode label {
     display:none;
}
/*Items*/
.category-products-grid .item {
     border:none;
}

.module-search-results__products .item .module-search-results__item .product-description-wrapper .item-code-label {
     display: none;
}

.module-search-results__products .item .module-search-results__item .product-description-wrapper .item-code {
	white-space: break-spaces;
}

.module-search-results__products .item .product-image-wrapper,
.category-products-grid .item .product-image-wrapper.product-description-wrapper{
     max-height: 150px;
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
}
.module-search-results__products .item .module-search-results__item h2.product-name,
.item .module-category-product-listing h2.product-name {
     margin-bottom:15px;
     margin-top:0;
     max-height: 70px;
    min-height: 70px;
    display: flex;
}
.module-search-results__products .item .module-search-results__item h2.product-name a,
.item .module-category-product-listing h2.product-name a {
     color:#000;
     overflow: hidden;
     flex: 1;
}
.module-search-results__products .item .product-image-wrapper,
.item .module-category-product-listing .product-image-wrapper.product-description-wrapper {
     margin-bottom:10px;
    display: flex;
    justify-content: center;
    align-items: center;  
}

.module-search-results__products .item .product-image-wrapper img {
     max-width: 250px;
     max-height: 188px;
 }

.module-search-results__products .item .product-description-wrapper .product-shop-inner,
.item .module-category-product-listing .product-description-wrapper .product-shop-inner {
     min-height:60px;
}
.item .module-category-product-listing .alternate-item-code {
     font-weight:600;
}
.category-products-listing ul.add-to-links {
     display:none;
}
.module-category-product-listing__alternateItemCode {
     margin-top:7px;
}
@media only screen and (max-width:1599px) {
     .module-category-product-listing__alternateItemCode > span{
         font-size:13px;
    }
}
.module-category-product-listing #listing-ul .item .price-box {
     margin-top:15px;
}

.module-search-results__products .item .module-search-results__item .price-box p {
     font-size: 18px;
 }

.module-search-results__products .item .module-search-results__item .product-description-wrapper .item-code,
.module-search-results__products .item .module-search-results__item .price-box span,
.item .module-category-product-listing .price-box span{
     font-weight:600;
}

.item .module-category-product-listing .price-box span{
     color:#c81d24;
}
.category-products-listing .product_pricetag {
     margin-right:5px;
}
@media only screen and (min-width:1400px) and (max-width:1599px) {
     .category-products-listing .product_pricetag:before {
         display:block;
    }
}
.category-products-listing .price > span:not(.product_pricetag) {
     color:#000;
}
@media only screen and (min-width:768px) {
     .item .module-category-product-listing .add-cart-wrapper {
         display:flex;
         justify-content:center;
    }
     .module-category-product-listing .products-list .item .add-cart-wrapper {
         flex-direction:column;
         gap:20px;
    }
     .item .module-category-product-listing .add-cart-wrapper > .qty-wrapper{
         margin:auto 10px;
    }
     .item .module-category-product-listing .add-cart-wrapper > button{
         margin:auto;
         margin-left:10px;
    }
}
.item .module-category-product-listing .add-cart-wrapper input.qty {
     height:40px;
     border:1px solid #000;
}
@media only screen and (max-width:959px) {
     .item .module-category-product-listing .grid12-12, .breadcrumbs_col .grid-column-wrapper {
         overflow-y:hidden;
    }
     .item .module-category-product-listing .item-code-label {
         display:none;
    }
     .mobile-filters .fa-sliders:before {
         font-size:22px;
         font-weight:700;
    }
}
/*Hover effect*/
.category-products-grid .item:hover {
     z-index:10;
     box-shadow:0 0 20px rgba(0,0,0,.2);
     border-color:transparent;
     transition:box-shadow .4s ease-out;
     -moz-transition:box-shadow .4s ease-out;
     -webkit-transition:box-shadow .4s ease-out;
     -o-transition:box-shadow .4s ease-out;
}
/*Expand the width for small width devices*/
@media only screen and (max-width:959px) {
     .product-listing {
         width:98% !important;
    }
     .category-pages.main.container {
         width:98% !important;
         max-width:100% !important;
    }
     .product-listing .add-cart-button span span {
         width:100px;
    }

}
@media only screen and (max-width:767px) {
     .toolbar-section p, .toolbar-section a, .toolbar-section strong, .toolbar-section label, .toolbar-section li, .toolbar-section .per-page{
         font-size:12px;
    }
     .toolbar-section select {
         height:26px;
         padding:3px;
    }
     .pager .pages li {
         height:25px;
         line-height:25px;
    }
     .products-grid .item button.button span span {
         padding:0px !important;
         height:40px !important;
         line-height:40px;
    }
     .pager .pages li {
         display:inherit;
    }

     .product-listing a, .product-listing span, .product-listing div {
         font-size:13px;
    }
     .category-products-grid .item {
         padding-bottom:95px !important;
    }
}

.page-content ul, #product-tabs .std ul, .product-shop.module-product-details ul:not(.add-to-links) {
     list-style:disc;
     margin-left:25px;
}
#header {
     position:sticky;
     top:0;
     z-index:50;
}
@media print {
     .ord-details .grid-column-wrapper {
         width:98%;
    }
     .module-trans-details-listing {
         max-width:95%;
    }
     .module-order-details-listing {
         max-width:95%;
    }
     @page {
         size:landscape 
    }
}
.isPublic .hide-for-B2C {
     display:none;
}
body:not(.isPublic) .hide-for-B2B {
     display:none;
}

/**Footer**/
/*footer*/
.footer-top .section {
     padding:0 !important;
}
.collapsible.mobile-collapsible li a, #footer .copyright-txt, div.social-icons .fa, .social-icons span, .sbscrb-txt {
     color:#fff;
}

.collapsible ul.bullet li {
     background:none;
     padding-left:0;
     margin-bottom:5px;
}
.collapsible ul.bullet li:hover a {
     border-bottom:1px solid #fff;
}
.collapsible h6.block-title, .sbscrb-hd, div.social-icons span {
     font-size:16px;
     color:#fff;
     letter-spacing:0;
     font-weight:700;
     text-transform:uppercase;
     font-family: 'nimbus-med' !important;

}
.collapsible h6.block-title {
letter-spacing: 1px;
}
.collapsible.mobile-collapsible li a, .sbscrb-txt {
    font-size:14px;
    font-family: 'nimbus-med' !important;
}
#footer .copyright-txt {
    font-family: 'nimbus-med' !important;
}
#footer .logo-img img {
     max-width:250px;
}
#footer input#email {
     width:70%;
     background-color:#222;
     border:none;
     border-bottom:1px solid #fff;
     color:#fff;
     padding-left:10px;
}
#footer input#email::placeholder {
     font-size:16px;
     color:#ccc;
     letter-spacing:0.5px;
}
button#subscrb-btn {
     width:60px;
     height:27px;
     background-color:#c81d24 ;
     position:relative;
     transform:skewX(-20deg);
     color:#fff;
     font-weight:bold;
     margin-top:9px;
}
.subscr-form {
     margin:8% 0;
}
.sbscrb-txt {
     margin:4% 0;
     margin-bottom:6%;
}
.payment-icons-col {
     width:61%;
}
.copyright-col {
     float:right;
}
.copyright-txt {
     height:50px;
     line-height:50px;
}
.payment-icons img {
     width:50px;
}
.payment-icons {
     display:flex;
     justify-content:end;
     gap:2%;
}
div.social-icons {
     display:flex;
     justify-content:start;
     font-weight:700;
     padding-top:20px;
}
div.social-icons .fa {
     font-size:26px;
}
.social-icons > div:nth-child(1) {
     width:40%;
}
.social-icons > div:nth-child(2){
     display:flex;
     justify-content:space-evenly;
     width:60%;
}
@media only screen and (max-width:1259px) {
     #footer input#email {
         width:60%;
    }
     
}
@media only screen and (max-width:959px) {
     .footer-links-col {
         width:100% !important;
    }
     .footer-links-col .item-default > div {
         width:23%;
    }
     .subscribe-col, .payment-icons-col, .copyright-col {
         width:48% 
    }
     #footer input#email {
         width:60%;
    }
     
     .social-icons{
         padding:10px 0;
         padding-bottom:20px;
    }
}
@media only screen and (max-width:767px) {
     
     #footer .logo-img img {
         max-width:200px;
    }
     div.social-icons {
         padding-bottom:20px;
    }
     p.copyright-txt {
         padding-top:10px;
         font-size:13px;
    }
     .footer .collapsible .opener:hover {
         background-color:#df363c;
    }
     .subscribe-col{
         margin:5% 0;
    }
    .payment-icons {
        justify-content: space-between;
    }
    .payment-icons img {
        width:30px;
    }
}
/*Brand pages :start*/
.brand-page .outer-wrapper {
     display:flex;
     flex-wrap:wrap;
     margin-bottom:20px;
     justify-content:space-evenly;
     align-items:center;
     width:100%;
}
.brand-page .brand-text{
     text-align:center;
     font-size:16px;
     padding-left:50px;
     padding-right:50px;
}
.brand-page .section-header {
     text-align:center;
     padding-left:15px;
     padding-right:15px;
     padding-top:20px;
     padding-bottom:15px;
}
.brand-page .range-box {
     display:flex;
     flex-direction:column;
     align-items:center;
     margin:10px;
     max-width:275px;
     min-width:275px;
}
.brand-page .col-border {
     border:1px solid #727272;
     border-radius:5px;
}
.brand-page .range-header {
     text-align:center;
     font-size:24px;
     line-height:28px;
     padding:15px 20px;
}
.brand-page .product-text {
     text-align:left;
     font-size:16px;
}
.brand-page .column-productline {
     display:flex;
     flex-direction:column;
     align-items:flex-start;
     justify-content:center;
     margin:10px;
     max-width:600px;
}
.productline-header {
     text-align:center;
     padding:20px 0px;
}
.brand-prod-finder ul.form-list{
     display:grid;
     grid-template-columns:repeat(12, 1fr);
     grid-auto-rows:max-content;
     grid-gap:0 2%;
     padding-left:0;
}
.brand-prod-finder ul.form-list > li{
     grid-column:span 4/span 4;
     margin-bottom:30px;
}
/*Brand pages :end*/
/*MegaMenu*/
ul.level1.nav-panel {
     background-color:#222 !important;
     padding:20px 0 !important;
}
.nav-regular li.level0>.nav-panel--dropdown {
     border-top:none;
}
#nav .nav-panel--dropdown > .nav-item > a >span {
     color:#fff;
     font-weight:700;
}
#nav .nav-panel--dropdown .nav-item a .caret {
     border-left-color:#fff !important;
     /*border-right-color:#fff !important;*/
	border-right: none;
     opacity:1;
}
.desktop-category-column .nav-regular .nav-panel > li.classic {
     padding:5px 20px;
     margin-bottom:0;
}
.desktop-category-column .nav-regular .nav-panel > li.classic:hover {
     background-color:#c81d24;
}
.desktop-category-column .nav-regular .nav-item.level0:hover>a>span {
     color:#c81d24;
}
.desktop-category-column #nav .nav-item.level0.parent:hover>a .caret{
     border-top-color:#c81d24;
}
/*Megamenu Nav panel*/
.nav-regular .classic>.nav-panel--dropdown {
     border-top:none;
}
#nav ul.level1 .nav-panel--dropdown {
     background-color:#e5e5e5;
     width:50vw !important;
}
#nav .nav-panel--dropdown .nav-panel-inner .module-sub-categories > div.nav-item {
     display:flex;
     background-color:#fff;
     border-radius:10px;
     align-items:center;
     margin-bottom:2%;
}
#nav .nav-panel--dropdown .nav-panel-inner .module-sub-categories > div.nav-item > * {
     width:50%;
}
#nav .nav-panel--dropdown .nav-panel-inner {
     padding:0;
}
#nav .nav-panel--dropdown .nav-panel-inner .module-sub-categories-img {
     text-align:center;
}
#nav .nav-panel--dropdown .nav-panel-inner .module-sub-categories-img img {
     max-width:80px;
     width:80px;
}
#nav .nav-panel--dropdown .nav-panel-inner .module-sub-categories-title span {
     font-size:14px;
     text-transform:capitalize;
     line-height:14px;
     font-weight:700;
}
#nav .nav-panel--dropdown .nav-panel-inner > div, #nav .nav-panel--dropdown .nav-panel-inner > div > div{
     width:100%;
     margin:0;
}
#nav .nav-panel--dropdown .nav-panel-inner .cat-title {
     margin-left:1%;
     margin-bottom:2%;
}
#nav .nav-panel--dropdown .nav-panel-inner .cat-title span {
     font-weight:900;
     font-size:14px;
     text-transform:uppercase;
     font-family:'nimbus-med', sans-serif;
}
.nav-regular.opt-fx-fade-inout .nav-panel .nav-item--parent:hover>.nav-panel--dropdown {
     transition-delay:0 !important;
     -moz-transition-delay:0 !important;
     -webkit-transition-delay:0 !important;
     -o-transition-delay:0 !important;
}
.nav-panel--dropdown.nav-panel.tmp-full-width {
     position:fixed;
     top:0px !important;
}
.nav-regular.opt-fx-fade-inout .nav-panel .nav-item--parent>.nav-panel--dropdown {
     transition:none !important;
}
ul.level1.nav-submenu.nav-panel--dropdown.nav-panel {
     left:30% !important;
     transform:translateX(-30%);
     top:50px !important;
}
.temp-panel-for-hover {
     position:absolute;
     top:50px;
     width:50em;
     background-color:transparent;
     display:none;
     left:calc(30% + 16em);
     transform:translateX(-30%);
     padding:20px 0;
}
.desktop-category-column .nav-regular .nav-item.level0:hover > .temp-panel-for-hover {
     display:block;
}
.desktop-category-column .nav-regular .temp-li {
     height:1px;
     padding:0;
     background-color:#222 !important;
}
.desktop-category-column .nav-regular .temp-li a span {
     color:#222 !important;
}
/*Misc*/
.top-header-container .module-user-links ul.links .link-wishlist {
     display:none;
}
@media only screen and (min-width:1800px) {
     .desktop-category-column #nav {
         margin-left:-40px;
         float:right;
    }
}
.footer-top.footer.container {
     width:80%;
     margin:0 auto;
}
.footer-top .section {
     padding:10px 0 !important;
     width:100%;
}
.footer-top .logo-img, .footer-top .subscr-form, .footer-top .copyright-txt, .social-icons>div:nth-child(1) {
     text-align:end;
}
.social-icons>div:nth-child(2) >a {
     flex:1;
     text-align:end;
}
.item .module-category-product-listing h2.product-name a {
     color:#000;
     overflow:hidden;
     flex:1;
}
/*Content pages*/
@media only screen and (min-width:960px) {
     .content-pages.main {
         width:80%;
         margin:0 auto;
    }
}
.content-pages .grid-group.grid-group-1 .grid-container-wrapper {
     margin-top:5%;
}
 .content-pages .grid-group:last-child .grid-container-wrapper:last-child {
     padding-bottom:3%;
     border-bottom:1px solid #ddd;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .content-pages .grid-container-wrapper {
         overflow-x:hidden;
    }
}
/*cart*/
#mini-cart .feature-icon-hover .empty, #mini-cart .caret {
     display:none;
}
#mini-cart .feature-icon-hover {
     padding:0;
}
/**/
.progress, div[class^=strength_meter_passwd] {
     top:25px !important;
     width:100% !important;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide {
     margin-bottom:70px;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu {
     display:flex;
     float:left;
     width:100%;
     background-color:#fff;
     padding-left:0 !important;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li {
     margin:0 auto;
     list-style:none;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li:hover {
     background-color:#c81d24;
     border-radius:5px;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li:hover a{
     color:#fff;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li > a{
     font-weight:bold;
     padding:0 12px;
     line-height:40px;
}
.account-view .block-content.mm-menu ul.xm-mega-menu > li {
     width:100%;
}
.account-view .user-grid-content .page-title {
     overflow:hidden;
}
.xm-grid-account .grid-container-wrapper {
     overflow-x:hidden;
}
#order-table, #transhistory-table, #address-book-table{
     margin-top:20px;
}
.module-order-details-actions p.back-link .fa-angle-double-left:before {
     content:"";
}
@media only screen and (min-width:960px) {
     .order-info-box.module-order-details-billing, .order-info-box.module-order-details-shipping, .order-info-box.module-shipment-details-billing, .order-info-box.module-trans-details-shipping, .col-1.addresses-primary .item, .col-2.addresses-primary .item{
         min-height:290px;
    }
     /*Align the misc info with billing data*/
     .module-order-details-misc > div {
         padding-left:10px;
    }
}
.buttons-set.module-order-details-actions {
     text-align:end;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .billing-module, .shipping-module, .col-1.addresses-primary, .col-2.addresses-primary {
         width:99%;
    }
     .col-2.addresses-primary {
         float:left;
    }
}
@media only screen and (max-width:959px) {
     .ReactTable .rt-thead.-filters input{
         max-width:80px;
    }
     .ReactTable .rt-thead.-filters select{
         width:80%;
         height:26px;
    }
     .ReactTable a, .ReactTable div, .ReactTable span{
         font-size:11px !important;
    }
     .module-account-transhistory-content-placeholder {
         margin-top:20px;
    }
     .module-order-details-listing, .ord-comment {
         overflow-x:hidden;
    }
}
#openinvc-href span span{
     padding:10px;
}
.page-title.module-trans-details-status h1 span {
     font-size:40px;
}
.module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie, #openinvc-href span span, a.button span span {
     padding:10px;
     background-color:#c81d24 ;
     border-radius:5px;
     cursor:pointer;
     color:#fff;
     border:1px solid #c81d24;
}
.module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie:hover {
     background-color:#8f0000 ;
     color:#fff;
}
.module-trans-details-actions input.form-control {
     height:30px;
     width:70px;
}
@media only screen and (max-width:767px) {
     button[title='Change Email'] {
         margin-bottom:50px !important;
    }
}
.login-section span.company-name {
     display:none;
}
.login-section .grid-container {
     display:flex;
     flex-direction:column;
}
.login-section h1 {
     text-align:center;
}
.login-section .grid-column-wrapper.grid12-12.last {
     width:50%;
     margin:2% auto;
}
@media only screen and (max-width:1199px) {
     .login-section {
         width:98%;
    }
}
/*Pay Open Invoice*/
.po-invc .pager .amount {
     padding-top:0;
}
.po-invc #form_filter_user_data input, .po-invc #form_filter_user_data button {
     margin-left:10px;
}
.po-invc .buttons-set p.back-link {
     display:none;
}
@media only screen and (max-width:767px) {
     .po-invc #form_filter_user_data {
         display:flex;
         margin-bottom:20px;
    }
     .po-invc #form_filter_user_data > * {
         margin:auto;
    }
     .po-invc .filter_order_search_label {
         line-height:12px;
    }
     .po-invc .my-account .pager p.amount strong, .po-invc .filter_order_search_label, .po-invc .my-account > p, .po-invc button.button span, .po-invc .pages strong, .po-invc .pages li, .po-invc .pages li a{
         font-size:12px !important;
         font-weight:400 !important;
    }
     .po-invc .pages ol {
         padding-left:0;
         margin-top:5px;
    }
     .po-invc .my-account .pager p.amount {
         padding-left:5px;
         float:left;
    }
}
/*sidebar filters*/
.sidebar-wrapper.module-category-filters li a, #filter_list_box dt {
     font-family:'nimbus-reg', sans-serif !important;
     color:#000;
     letter-spacing:1px;
     font-size:18px;
}
.sidebar .block-content #filter_list_box dd ol {
     max-height:300px;
}
.alternate-item-code {
     font-size:14px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
     font-size:16px;
}
.item .module-category-product-listing .product-image-wrapper.product-description-wrapper img {
     max-height:188px;
}
.category-products-grid .item .product-image-wrapper.product-description-wrapper {
     max-width:250px;
}
/*Sidebar*/
.sidebar-wrapper.module-category-filters .block-title {
     color:#fff;
     padding:5px 10px;
     background-color:#222;
}
.sidebar-wrapper.module-category-filters .mmenu-target-filter, .sidebar-wrapper.module-category-filters .mmenu-target-categories{
     width:80%;
}
.sidebar-wrapper.module-category-filters .mmenu-target-filter .block-content > div:nth-child(1) {
     display:none;
}
#filter_list_box, #mm-filter_list_box, .sidebar-wrapper.module-category-filters .mmenu-target-categories .block-content {
     margin-bottom:0;
     border:1px solid #ddd;
}
#filter_list_box dt, #filter_list_box dd, #mm-filter_list_box dt, #mm-filter_list_box dd {
     padding:5px 10px;
}
#filter_list_box dt, #mm-filter_list_box dt{
     border-top:1px solid #ddd;
     padding-top:20px;
}
.module-category-filters .fa-angle-down:before {
     content:"\f068" !important;
}
.module-category-filters .fa-angle-down.up:before {
     content:"\f067" !important;
}
.sidebar .block:first-child {
     margin:0;
}
.sidebar.sidebar-filter, .sidebar.sidebar-category {
     margin-top:0 !important;
}
.sidebar-wrapper.module-category-filters .accordion-style1 li:last-child a {
     border-bottom:none;
}
.sidebar-wrapper.module-category-filters .block-content li > a {
     display:flex;
     align-items:center;
     padding-bottom:10px;
}
.sidebar-wrapper.module-category-filters .block-content li > a > .fa {
     margin-right:5px;
}
.sidebar-wrapper.module-category-filters .block-content li > a > .item-count{
     flex:1;
     text-align:end;
}
.sidebar-wrapper.module-category-filters .block-content li > a > .item-count span{
     padding:2px 7px;
     background-color:#eee;
     border-radius:10px;
     font-weight:800;
}
.sidebar-wrapper.module-category-filters .mm-navbar__title {
     display:none;
}


/** Blog **/
#blogcrumbs ul > li {
	display: inline-block;
}


.blog-author {
	display: none;
}

.blog-page h2 a {
	color: #000;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
}

.bootstrap-styles .row {
	margin-left: 0 !important;
	margin-right: 0 !important;;
}

.bootstrap-styles p {
	margin: 10px;
	font-size: 18px;
}

.bootstrap-styles figure {
	text-align: center;
}

@media (min-width: 992px) {
	.bootstrap-styles .article-block.col-md-9 {
		width: 100% !important;
	}
	.blog-page .article-block .blog-post-container {
		width: 100%;
		grid-template-columns: 1fr 1fr;
		padding: 20px;
		display: grid;
	}
	.blog-page-wrapper .blog-featured-image {
		padding: 5px !important;
		display: block !important;;
		float: none !important;
		width: 100% !important;;
	}
	.blog-page-wrapper .blog-featured-image img {
		width: 100% !important;
		float: none !important;
	}
}

@media only screen and (min-width: 1280px) {
	.blog-page-wrapper .blog-featured-image {
		padding: 5px !important;
		display: block !important;;
		float: none !important;
		width: 100% !important;;
	}
	.blog-page-wrapper .blog-featured-image img {
		width: 100% !important;
		float: none !important;
	}
}